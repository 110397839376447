import React from "react";

import SubHeading from "../SubHeading/SubHeading";
import "./Newsletter.css";

const Newsletter = () => (
  <div className="app__newsletter">
    <div className="app__newsletter-heading">
      <SubHeading title="Nyhetsbrev" />
      <h1 className="headtext__cormorant">Prenumerera på vårt nyhetsbrev</h1>
      <p className="p__opensans">
        Och missa aldrig de senaste uppdateringarna!
      </p>
    </div>
    <div className="app__newsletter-input flex__center">
      <input type="email" placeholder="Skriv in din mailadress" />
      <button type="button" className="custom__button">
        Prenumerera
      </button>
    </div>
  </div>
);

export default Newsletter;
