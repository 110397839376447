import images from "./images";

const wines = [
  {
    title: "Chapel Hill Shiraz",
    price: "580:-",
    tags: "AU | Flaska",
  },
  {
    title: "Catena Malbee",
    price: "599:-",
    tags: "AU | Flaska",
  },
  {
    title: "La Vieillw Rose",
    price: "449:-",
    tags: "FR | 750 ml",
  },
  {
    title: "Rhino Pale Ale",
    price: "299:-",
    tags: "CA | 750 ml",
  },
  {
    title: "Irish Guinness",
    price: "259:-",
    tags: "IE | 750 ml",
  },
];

const cocktails = [
  {
    title: "Aperol Sprtiz",
    price: "199:-",
    tags: "Aperol | Villa Marchesi prosecco | soda | 30 ml",
  },
  {
    title: "Dark 'N' Stormy",
    price: "159:-",
    tags: "Dark rum | Ginger beer | Slice of lime",
  },
  {
    title: "Daiquiri",
    price: "99:-",
    tags: "Rum | Citrus juice | Sugar",
  },
  {
    title: "Old Fashioned",
    price: "299:",
    tags: "Bourbon | Brown sugar | Angostura Bitters",
  },
  {
    title: "Negroni",
    price: "259:-",
    tags: "Gin | Sweet Vermouth | Campari | Orange garnish",
  },
];

const awards = [
  {
    imgUrl: images.award02,
    title: "Resturangernas pris",
    subtitle:
      "Enastående resturang, smakuookevelser som överträffar förväntningar.",
  },
  {
    imgUrl: images.award01,
    title: "Rising Star",
    subtitle: "Uppkommande stjärna,strålar med talang och potential.",
  },
  {
    imgUrl: images.award05,
    title: "AA Hospitality",
    subtitle: "Innovation och medkänsla för samhällsförändring.",
  },
  {
    imgUrl: images.award03,
    title: "Outstanding Chef",
    subtitle: "Exceptionell kock, skapar smaker som förvandlar måltider.",
  },
];

export default { wines, cocktails, awards };
