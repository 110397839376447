import { images } from "../../constants";
import "./AboutUs.css";

const AboutUs = () => (
  <div
    className="app__aboutus app__bg flex__center section__padding"
    id="about"
  >
    <div className="app__aboutus-overlay flex__center">
      <img src={images.O} alt="O letter" />
    </div>

    <div className="app__aboutus-content flex__center">
      <div className="app__aboutus-content_about">
        <h1 className="headtext__cormorant">Om Oss</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans">
          Opulence är en banbrytande restaurangupplevelse som kombinerar lyxig
          atmosfär med förstklassig mat och enastående service. Vårt engagerade
          team arbetar passionerat för att skapa minnesvärda stunder och
          överträffa våra gästers förväntningar vid varje besök.
        </p>
        <button type="button" className="custom__button">
          Veta Mer
        </button>
      </div>

      <div className="app__aboutus-content_knife flex__center">
        <img src={images.knife} alt="about_knife" />
      </div>

      <div className="app__aboutus-content_history">
        <h1 className="headtext__cormorant">Vår Historia</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans">
          Vår historia började med en passion för att skapa enastående
          matupplevelser. Genom hårt arbete, innovation och dedikation har vi
          vuxit till att bli en framstående destination för gourmetmat och
          minnesvärda stunder för våra gäster genom åren.
        </p>
        <button type="button" className="custom__button">
          Veta Mer
        </button>
      </div>
    </div>
  </div>
);

export default AboutUs;
