import React from "react";
import { FiFacebook, FiTwitter, FiInstagram } from "react-icons/fi";

import { FooterOverlay, Newsletter } from "../../components";
import { images } from "../../constants";
import "./Footer.css";

const Footer = () => (
  <div className="app__footer section__padding" id="login">
    <FooterOverlay />
    <Newsletter />

    <div className="app__footer-links">
      <div className="app__footer-links_contact">
        <h1 className="app__footer-headtext">Kontakta Oss</h1>
        <p className="p__opensans">Östra Torggatan 6, 652 24 Karlstad</p>
        <p className="p__opensans">1 234-567-8910</p>
        <p className="p__opensans">1 234-567-8910</p>
      </div>

      <div className="app__footer-links_logo">
        <img src={images.opulence} alt="footer_logo" />
        <p className="p__opensans">
          &quot;Det bästa sättet att hitta dig själv är att förlora dig själv i
          tjänsten av andra.&quot;
        </p>
        <img src={images.spoon} alt="spoon" style={{ marginTop: 15 }} />
        <div className="app__footer-links_icons">
          <FiFacebook />
          <FiTwitter />
          <FiInstagram />
        </div>
      </div>

      <div className="app__footer-links_work">
        <h1 className="app__footer-headtext">Öppettider</h1>
        <p className="p__opensans">Måndag - Fredag:</p>
        <p className="p__opensans">08:00 - 12:00</p>
        <p className="p__opensans">Lördag - Söndag:</p>
        <p className="p__opensans">10:00 - 23:00</p>
      </div>
    </div>

    <div className="footer__copyright">
      <p className="p__opensans">2024 &copy; Opulence. All Rights reserved.</p>
    </div>
  </div>
);

export default Footer;
